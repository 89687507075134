<template>
    <div class="row pt-4 mt-3">
        <div class="col-lg-3">
            <h2 class="h4">Logos</h2>
        </div>
        <div class="col-lg-9">
            <logo-dark class="mb-3" :my-firm="myFirm"></logo-dark> 
            <logo-light :my-firm="myFirm"></logo-light>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import temporaryUrls from '../../../api/temporary-urls';
import Images from '../../../mixins/Images';
import axios from 'axios';
import Forms from '../../../mixins/Forms';

import { useToast } from 'vue-toastification';
import LogoDark from './Logos/LogoDark.vue';
import LogoLight from './Logos/LogoLight.vue';

const toast = useToast();

export default {
    props: ['myFirm'],
    data() {
        return {}
    },
    created() {
    },
    components: {LogoDark, LogoLight}
};
</script>

<style lang="scss" scoped>
.file-input {
    display: none;
}

.logo-container {
    // border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
    background-color: rgb(31, 27, 45);
    display: inline-block;
}

.logo-wrapper {
    display: flex;
    justify-content: space-between;

    .left {
        flex-grow: 1;
    }

    .right {
        flex-shrink: 1;
        display: flex;
        align-items: center;
    }
}
</style>