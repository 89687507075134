<template>
    <div>
        <a href="#" class="btn btn-primary" @click.prevent="showModal = true">+ Add Price</a>

        <vue-final-modal v-model="showModal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0">
                        <h5 class="modal-title">
                            Create Hourly Price Guide
                        </h5>
                        <button type="button" class="btn-close" aria-label="Close" @click="showModal = false"></button>
                    </div>
                    <div class="modal-body">
                        <!-- Service -->
                        <div class="mb-4">
                            <label class="form-label" for="service">Service</label>
                            <input :disabled="isSaving" placeholder="e.g. Commercial litigation" v-model="form.service"
                                type="text" class="form-control" id="service" />
                        </div>
                        <!-- / Service -->

                        <!-- Lower Price -->
                        <div class="mb-4">
                            <label class="form-label" for="lowerPrice">Lower Hourly Price (£)</label>
                            <input :disabled="isSaving" placeholder="" v-model="form.lower_price"
                                type="number" class="form-control" id="lowerPprice" />
                        </div>
                        <!-- / Lower Price -->

                         <!-- Lower Price -->
                         <div class="mb-4">
                            <label class="form-label" for="upperPrice">Upper Hourly Price (£)</label>
                            <input :disabled="isSaving" placeholder="" v-model="form.upper_price"
                                type="number" class="form-control" id="upperPrice" />
                            <span class="text-muted" style="font-size: 14px;">Not required</span>
                        </div>
                        <!-- / Lower Price -->
                    </div>
                    <div class="modal-footer">
                        <button :disabled="!canSave || isSaving" @click.prevent="attemptSave" type="button"
                            class="btn btn-primary btn-sm">
                            <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>

                            {{ isSaving ? 'Saving' : 'Save' }}
                        </button>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
  
<script>
import Spinner from "@/components/Spinner.vue";
import { useToast } from 'vue-toastification';
const toast = useToast();
import myFirmPrices from "../../../../api/my-firm-prices";

export default {
    props: ['myFirm'],
    name: "CreateHourlyFeeButtonAndModal",
    components: { Spinner },
    data() {
        return {
            showModal: false,
            form: {
                service: '',
                lower_price: null,
                upper_price: null
            },
            isSaving: false,
            serverErrors: []
        }
    },
    methods: {
        reset() {
            this.form = {
                service: '',
                lower_price: null,
                upper_price: null
            }
        },

        determineParams() {
            return {
                ...this.form,
                lower_price: Number(this.form.lower_price) * 100,
                upper_price: this.form.upper_price ? Number(this.form.upper_price) * 100 : null,
                price_type: 'hourly'
            }
        },

        attemptSave() {
            let vm = this;
            vm.isSaving = true;
            vm.serverErrors = [];
            myFirmPrices.store(this.determineParams()).then(r => {
                vm.isSaving = false;
                vm.reset();
                toast.success('Price created successfully');
                vm.showModal = false;
                vm.$emit('reload');
            }).catch(e => {
                console.log(e);
                let errors;
                if (typeof e.response.data === 'object') {
                    errors = _.flatten(_.toArray(e.response.data.errors));
                } else {
                    errors = ['Something went wrong. Please try again.'];
                }
                toast.error(errors[0]);
                vm.serverErrors = errors;
            })
        }
    },
    watch: {
        favourite() {
            this.reset();
        }
    },
    computed: {
        canSave() {
            return (
                this.form.service
                && this.form.lower_price !== null
            );
        }
    }
}
</script>
  
<style scoped></style>