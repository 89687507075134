<template>
  <div class="row pt-4 mt-3">
    <div class="col-lg-3">
      <!-- <h2 class="h4">Fixed Fee Prices</h2> -->
      <h2 class="h4">Hourly <br>
        Price Guides</h2>
    </div>
    <div class="col-lg-9">
      <!-- Loading -->
      <div v-if="isLoading" class="border rounded-3 p-5 text-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <!-- / Loading -->

      <!-- Loaded and no results -->
      <div v-if="!isLoading && pricesToShow.length == 0" class="border rounded-3 p-4 text-left">
        <div class="text-danger fw-bold">Nothing to see here!</div>
      </div>
      <!-- / Loaded and no results -->

      <!-- Loaded && has results -->
      <div v-if="!isLoading && pricesToShow.length" class="border rounded-3 p-3 hourly-prices">
        <hourly-price @reload="fetch"
         v-for="(price) in pricesToShow" :key="`price-${price.id}`" :my-firm="myFirm"
          :price="price"></hourly-price>
      </div>
      <!-- / Loaded && has results -->

      <!-- Add button -->
      <div class="mt-3">
        <create-hourly-fee-button-and-modal @reload="fetch" :my-firm="myFirm"></create-hourly-fee-button-and-modal>
      </div>
      <!-- / Add button -->
    </div>
  </div>
</template>

<script>
import EditableField from "../../../components/Display/EditableField.vue";
import myFirmPrices from "../../../api/my-firm-prices";
import _ from 'lodash';
import HourlyPrice from './Prices/HourlyPrice.vue';
import CreateHourlyFeeButtonAndModal from "./Prices/CreateHourlyFeeButtonAndModal.vue";

export default {
  props: ['myFirm'],
  components: { EditableField, HourlyPrice, CreateHourlyFeeButtonAndModal },
  data() {
    return {
      isLoading: false,
      prices: []
    }
  },
  created() {
    this.fetch();
  },
  computed: {
    pricesToShow() {
      let filtered = _.filter(this.prices, (price) => {
        return price.price_type == 'hourly';
      });
      return _.orderBy(filtered, ['id'], ['asc']);
    }
  },
  methods: {
    fetch() {
      let vm = this;
      vm.isLoading = true;
      myFirmPrices.fetch().then((response) => {
        vm.prices = response.data;
        vm.isLoading = false;
      }).catch((error) => {
        vm.isLoading = false;
        console.log(error);
      });
    }
  }
};
</script>

<style scoped lang="scss">
.hourly-prices {
  .hourly-price:last-child {
    border-bottom: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>