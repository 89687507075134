import axios from 'axios';

export default {
    fetch() {
        return axios.get(window.API_BASE + '/my-firm/pricing');
    },

    store(params) {
        return axios.post(window.API_BASE + '/my-firm/pricing', params);
    },

    update(id, params) {
        return axios.put(window.API_BASE + '/my-firm/pricing/' + id, params);
    },

    delete(id) {
        return axios.delete(window.API_BASE + '/my-firm/pricing/' + id);
    }
}