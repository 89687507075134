<template>
  <div class="row pt-4 mt-3">
    <div class="col-lg-3">
      <h2 class="h4">Company Details</h2>
    </div>
    <div class="col-lg-9">
      <div class="border rounded-3 p-3" id="auth-info">
        <!-- Name-->
        <div class="border-bottom mb-3 pb-3">
          <editable-field property="display_name" name="Display Name" :initial-value="myFirm.display_name"
            endpoint="my-firm" type="text" @updated="reloadMyFirm"></editable-field>
        </div>
        <!-- / Name -->

        <!-- Description-->
        <div class="border-bottom mb-3 pb-3">
          <editable-field property="description" name="Short Description" :initial-value="myFirm.description"
            endpoint="my-firm" @updated="reloadMyFirm"></editable-field>
        </div>
        <!-- / Description -->

        <!-- About Us -->
        <div class="border-bottom mb-3 pb-3">
          <editable-field property="about_us" name="About Us" :initial-value="myFirm.about_us" endpoint="my-firm"
            type="textarea" @updated="reloadMyFirm"></editable-field>
        </div>
        <!-- / About Us -->

        <!-- Location -->
        <div class="border-bottom mb-3 pb-3">
          <editable-field property="location" name="Location" :initial-value="myFirm.location" endpoint="my-firm"
            @updated="reloadMyFirm"></editable-field>
        </div>
        <!-- / Location -->

        <!-- Pricing Note -->
        <div class="">
          <editable-field property="pricing_note" name="Pricing Note (e.g. Min Fee)" :initial-value="myFirm.pricing_note" endpoint="my-firm"
            @updated="reloadMyFirm"></editable-field>
        </div>
        <!-- / Pricing Note -->
      </div>
    </div>
  </div>
</template>

<script>
import EditableField from "../../../components/Display/EditableField.vue";
export default {
  props: ['myFirm'],
  components: { EditableField },
  computed: {
  },
  methods: {
    reloadMyFirm() {
      this.$emit('reload');
    }
  }
};
</script>

<style></style>