<template>
    <div class="d-flex align-items-start justify-content-between pb-4 mb-2 border-bottom-md">
        <div class="d-flex align-items-start">
            <div class="position-relative flex-shrink-0">

                <h3 class="h3">
                    Update My Firm
                </h3>

                <p>
                    This controls how your firm is displayed when being advertised to clients.
                </p>

            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style></style>