import axios from 'axios';

export default {
    fetch() {
        return axios.get(window.API_BASE + '/my-firm');
    },

    update(params) {
        return axios.put(window.API_BASE + '/my-firm', params);
    }
}