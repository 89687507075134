<template>
  <div class="row pt-4 mt-3">
    <div class="col-lg-3">
      <h2 class="h4">Colours</h2>
    </div>
    <div class="col-lg-9">
      <div class="border rounded-3 p-3" id="auth-info">
        <!-- Dark Background Colour-->
        <div class="border-bottom pb-3 mb-3">
          <colour-field property="dark_background_colour" name="Background Colour"
            :initial-value="whitelabelConfiguration.dark_background_colour" endpoint="my-whitelabel-configuration"
            @updated="reloadWhitelabelConfiguration"></colour-field>
        </div>
        <!-- / Dark Background Colour -->

        <!-- Primary Colour-->
        <div class="">
          <colour-field property="primary_colour" name="Primary Colour"
            :initial-value="whitelabelConfiguration.primary_colour" endpoint="my-whitelabel-configuration"
            @updated="reloadWhitelabelConfiguration"></colour-field>
        </div>
        <!-- / Primary Colour -->
      </div>
    </div>
  </div>
</template>

<script>
import EditableField from "../../../components/Display/EditableField.vue";
import ColourField from "../../../components/Display/ColourField.vue";
export default {
  props: ['whitelabelConfiguration'],
  components: { EditableField, ColourField },
  computed: {
  },
  methods: {
    reloadWhitelabelConfiguration() {
      this.$emit('reload');
    }
  }
};
</script>

<style></style>