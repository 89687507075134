<template>
    <div class="border-bottom mb-3 pb-3 fixed-fee-price">
        <loading-screen :is-loading="isDeleting"></loading-screen>

        <div class="d-flex align-items-center justify-content-between" :class="{ 'py-2': isEditing }">
            <div class="pe-2 flex-grow-1" :class="{ 'opacity-40': isSaving }">
                <!-- Not editing -->
                <div v-if="!isEditing">
                    <label class="form-label fw-bold">{{ price.service }}</label>
                    <div>
                        {{ formatCostInPence(price.lower_price) }}
                        <span v-if="price.upper_price"> - {{ formatCostInPence(price.upper_price) }}</span>
                    </div>
                </div>
                <!-- / Not editing -->

                <!-- Editing -->
                <div v-if="isEditing">

                    <!-- Service -->
                    <div class="mb-3">
                        <label class="form-label fw-bold">
                            Service
                        </label>
                        <input @keyup.enter="attemptSave" :disabled="isSaving" v-model="formLocal.service"
                            class="form-control" />
                    </div>
                    <!-- / Service -->

                    <!-- Lower Price -->
                    <div class="mb-3">
                        <label class="form-label fw-bold">
                            Lower Price (£)
                        </label>
                        <input @keyup.enter="attemptSave" :disabled="isSaving" v-model="formLocal.lower_price" type="number"
                            class="form-control" />
                    </div>
                    <!-- / Lower Price -->

                    <!-- Lower Price -->
                    <div class="mb-3">
                        <label class="form-label fw-bold">
                            Upper Price (£)
                        </label>
                        <input @keyup.enter="attemptSave" :disabled="isSaving" v-model="formLocal.upper_price" type="number"
                            class="form-control" />
                    </div>
                    <!-- / Lower Price -->


                </div>
                <!-- / Editing -->

            </div>

            <!-- Not editing -->
            <div v-if="!isEditing" class="me-n3" aria-label="Edit">
                <a @click.prevent="edit" class="nav-link py-0 d-inline-block pe-2" href="#"><i class="fi-edit"></i></a>
                <a @click.prevent="attemptDelete" class="nav-link py-0 d-inline-block text-danger ps-2" href="#"><i
                        class="fi-trash"></i></a>
            </div>
            <!-- / Not editing -->

            <!-- Editing -->
            <div v-if="isEditing" class="actions-wrapper" aria-label="Save">
                <button v-if="!isSaving" type="button" class="btn btn-danger btn-sm mx-2" @click.prevent="cancel">
                    Cancel
                </button>

                <button v-if="!isSaving" type="button" class="btn btn-primary btn-sm" @click.prevent="attemptSave">
                    Save
                </button>

                <button v-if="isSaving" type="button" class="btn btn-primary btn-sm" :disabled="isSaving">
                    <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Saving
                </button>
            </div>
            <!-- / Editing -->
        </div>
    </div>
</template>

<script>
import Display from '../../../../mixins/Display';
import myFirmPrices from '../../../../api/my-firm-prices';
import Forms from '../../../../mixins/Forms';
import LoadingScreen from '../../../../components/LoadingScreen.vue';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
    props: ['price', 'myFirm'],

    mixins: [Display, Forms],

    components: {LoadingScreen},

    data() {
        return {
            isEditing: false,
            isSaving: false,
            isDeleting: false,
            formLocal: {},
            serverErrors: []
        }
    },

    methods: {
        edit() {
            this.isEditing = true;
            this.formLocal = {
                ...this.price,
                lower_price: this.price.lower_price ? this.price.lower_price / 100 : null,
                upper_price: this.price.upper_price ? this.price.upper_price / 100 : null
            };
        },

        cancel() {
            this.isEditing = false;
            this.formLocal = {}
        },

        determineFormParams() {
            return {
                ...this.formLocal,
                lower_price: Number(this.formLocal.lower_price) * 100,
                upper_price: this.formLocal.upper_price ? this.formLocal.upper_price * 100 : null
            }
        },

        attemptSave() {
            this.serverErrors = [];
            this.save();
        },

        save() {
            let vm = this;
            vm.isSaving = true;
            myFirmPrices.update(vm.price.id, vm.determineFormParams()).then(r => {
                vm.isSaving = false;
                vm.isEditing = false;
                vm.$emit('reload');
            }).catch(e => {
                vm.isSaving = false;
                vm.setAndNotifyErrors(e, "Error saving new " + this.name);
            });
        },

        attemptDelete() {
            let vm = this;
            swal({
                title: "Are you sure you want to delete this price?",
                icon: "warning",
                dangerMode: true,
                buttons: {
                    cancel: true,
                    confirm: "Yes, Delete"
                }
            }).then((shouldDelete) => {
                if (shouldDelete) {
                    vm.delete();
                }
            });
        },

        delete() {
            let vm = this;
            vm.isDeleting = true;
            myFirmPrices.delete(vm.price.id).then(r => {
                vm.isDeleting = false;
                vm.$emit('reload');
                toast.success('Price deleted successfully');
            }).catch(e => {
                vm.isDeleting = false;
                vm.setAndNotifyErrors(e, "Error deleting price");
            });
        }
    }
}
</script>

<style></style>