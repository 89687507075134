<template>
    <div class="d-flex align-items-center justify-content-between edit-colour-field" :class="{ 'py-2': isEditing }">
        <div class="pe-2 flex-grow-1" :class="{ 'opacity-40': isSaving }">
            <!-- Not editing -->
            <div v-if="!isEditing">
                <label class="form-label fw-bold">{{ name }}</label>
                <div v-if="type === 'password'"> ***********</div>
                <div v-else>
                    <span v-if="type !== 'textarea'">
                        {{ currentValue ? formatRgb(currentValue) : "Not provided" }}
                    </span>
                    <span class="textarea-display" v-if="type === 'textarea'" v-html="currentValue"></span>
                </div>
            </div>
            <!-- / Not editing -->

            <!-- Editing && Confirm Current -->
            <div v-if="isEditing && confirmCurrent" class="mb-4">
                <label class="form-label fw-bold">
                    Current {{ name }}
                </label>
                <input @keyup.enter="attemptSave" :type="type" v-model="currentConfirmationValue" class="form-control" />
            </div>
            <!-- / Ediint && Confirm Previous -->

            <!-- Editing -->
            <div v-if="isEditing">
                <label class="form-label fw-bold">
                    {{ confirmPrevious ? 'New ' : null }}
                    {{ name }}
                </label>

                <div class="colour-wrapper">
                    <div class="left">
                        <ColorPicker format="rgb" v-model:pureColor="newValue" shape="square" />
                    </div>
                    <div class="right">
                        <!-- <input @keyup.enter="attemptSave" :type="type" v-model="newValue" class="form-control" -->
                            <!-- v-if="type !== 'textarea'" /> -->

                            <div>{{ formatRgb(this.newValue) }}</div>
                    </div>
                </div>

            </div>
            <!-- / Editing -->
        </div>

        <!-- Not editing -->
        <div v-if="!isEditing" class="me-n3" aria-label="Edit">
            <a @click.prevent="edit" class="nav-link py-0" href="#"><i class="fi-edit"></i></a>
        </div>
        <!-- / Not editing -->

        <!-- Editing -->
        <div v-if="isEditing" class="actions-wrapper" aria-label="Save">
            <button v-if="!isSaving" type="button" class="btn btn-danger btn-sm mx-2" @click.prevent="cancel">
                Cancel
            </button>

            <button v-if="!isSaving" type="button" class="btn btn-primary btn-sm" @click.prevent="attemptSave">
                Save
            </button>

            <button v-if="isSaving" type="button" class="btn btn-primary btn-sm" :disabled="isSaving">
                <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Saving
            </button>
        </div>
        <!-- / Editing -->
    </div>
</template>

<script>
import axios from "axios";
import Forms from "@/mixins/Forms";

import { useToast } from "vue-toastification";

const toast = useToast();

export default {
    props: ["property", "name", "initialValue", "endpoint", "type", "confirmed", "confirmCurrent"],
    mixins: [Forms],
    data() {
        return {
            isEditing: false,
            currentValue: null,

            currentConfirmationValue: null,
            newValue: null,
            newValueConfirmation: null,

            isSaving: false,
            serverErrors: [],
        };
    },
    methods: {
        edit() {
            this.isEditing = true;
            if (!this.confirmCurrent) {
                this.newValue = this.currentValue;
                this.newValue = this.formatRgb(this.newValue);
            }
            this.currentConfirmationValue = null;
            this.newValueConfirmation = null;
        },

        cancel() {
            this.isEditing = false;
            this.newValue = null;
            this.currentConfirmationValue = null;
            this.newValueConfirmation = null;
        },

        formatRgb(val) {
            let rgb;
            if (val) {
                if (val.includes('rgb(')) {
                    rgb = val.substring(val.indexOf("(") + 1, val.indexOf(")"));
                } else {
                    rgb = val;
                }
            }
            return 'rgb(' + rgb + ')';
        },

        attemptSave() {
            if (this.newValue) {
                if (this.newValue.includes('rgb(')) {
                    let rgb = this.newValue.substring(this.newValue.indexOf("(") + 1, this.newValue.indexOf(")"));
                    this.newValue = rgb;
                }
            }

            this.save();
        },

        save() {
            this.serverErrors = [];
            this.isSaving = true;

            let params = {};
            params[this.property] = this.newValue;
            if (this.confirmed) {
                params[this.property + '_confirmation'] = this.newValueConfirmation;
            }
            if (this.confirmCurrent) {
                params['current_' + this.property] = this.currentConfirmationValue;
            }
            axios
                .put(window.API_BASE + "/" + this.endpoint, params)
                .then((r) => {
                    this.currentValue = this.newValue;
                    this.newValue = null;
                    this.isSaving = false;
                    this.isEditing = false;
                    toast.success(this.name + " saved successfully!");
                    this.$emit("updated");
                })
                .catch((e) => {
                    console.log(e);
                    this.isSaving = false;
                    this.setAndNotifyErrors(e, "Error saving new " + this.name);
                });
        },
    },
    created() {
        this.currentValue = this.initialValue;
    },
};
</script>

<style lang="scss">
.edit-colour-field {
    .actions-wrapper {
        padding-top: 29px;
    }

    .btn.btn-sm {
        padding-top: 0.63rem;
        padding-bottom: 0.63rem;
    }

    .textarea-display {
        display: block;
        white-space: pre-line;
        padding-top: 10px;
    }

    .colour-wrapper {
        display: flex;
        margin-top: 15px;

        .left {
            flex-shrink: 1;
            padding-right: 10px;
            padding-left: 3px;

            .vc-color-wrap {
                height: 40px;
                border-radius: 5px !important;
            }
        }

        .right {
            flex-grow: 1;
            display: flex;
            align-items: center;
        }
    }
}
</style>