<template>
  <div>
    <dark-header-background></dark-header-background>
    <div class="container content-overlay mt-5 mb-md-4 py-5">
      <breadcrumb class="mb-3 mb-md-4 pt-md-3" type="light" :crumbs="crumbs"></breadcrumb>

      <!-- Loading -->
      <div v-if="isLoading || !myFirm" class="bg-light shadow-sm rounded-3 p-4 p-md-5 mb-2 text-center loading-section">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <!-- / Loading -->

      <!-- Card - Not Loading -->
      <div v-if="!(isLoading || !myFirm)" class="bg-light shadow-sm rounded-3 p-4 p-md-5 mb-2">
        <section-header></section-header>

        <company-details :my-firm="myFirm" @reload="loadMyFirmSilently"></company-details>

        <logos :my-firm="myFirm"></logos>

        <fixed-fee-prices :my-firm="myFirm"></fixed-fee-prices>

        <hourly-prices :my-firm="myFirm"></hourly-prices>

        <!-- <company-details :whitelabel-configuration="whitelabelConfiguration"
          @reload="loadWhitelabelConfigurationSilently"></company-details>

        <logos :whitelabel-configuration="whitelabelConfiguration"></logos>

        <colours :whitelabel-configuration="whitelabelConfiguration"></colours> -->
      </div>
      <!-- / Card - Not Loading -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '../../components/Breadcrumb.vue'
import DarkHeaderBackground from "../../components/DarkHeaderBackground.vue"
import { useToast } from 'vue-toastification'
import SectionHeader from './Edit/SectionHeader.vue'
import CompanyDetails from './Edit/CompanyDetails.vue'
import myFirm from '../../api/my-firm'
import Logos from './Edit/Logos.vue'
import Colours from './Edit/Colours.vue'
import FixedFeePrices from './Edit/FixedFeePrices.vue'
import HourlyPrices from './Edit/HourlyPrices.vue'

const toast = useToast();

export default {
  components: { DarkHeaderBackground, Breadcrumb, SectionHeader, CompanyDetails, Logos, Colours, FixedFeePrices, HourlyPrices },
  data() {
    return {
      crumbs: [
        {
          href: '/',
          label: 'Home'
        },
        {
          href: '#',
          label: 'My Firm',
          active: true
        }
      ],

      isLoadingMyFirm: false,
      myFirm: null
    }
  },
  computed: {
    ...mapGetters('auth', {
      canEditMyFirm: 'canEditMyFirm'
    }),

    isLoading() {
      return this.isLoadingMyFirm;
    }
  },
  methods: {
    loadMyFirm() {
      let vm = this;
      vm.isLoadingMyFirm = true;
      myFirm.fetch().then(r => {
        vm.myFirm = r.data;
      }).catch(e => {
        toast.error('There was an error loading your firm.');
      }).finally(() => {
        vm.isLoadingMyFirm = false;
      })
    },

    loadMyFirmSilently() {
      let vm = this;
      myFirm.fetch().then(r => {
        vm.myFirm = r.data;
      }).catch(e => {
        toast.error('There was an error loading your firm.');
      });
    }
  },
  created() {
    if (!this.canEditMyFirm) {
      toast.error('You are not authorized to access this page.');
      this.$router.push('/');
    } else {
      this.loadMyFirm();
    }
  }
}
</script>

<style scoped lang="scss">
.loading-section {
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>