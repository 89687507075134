<template>
      <div class="position-absolute top-0 start-0 w-100 bg-dark" style="height: 398px;"></div>
</template>

<script>
export default {

}
</script>

<style>

</style>